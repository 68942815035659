import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

import SEO from "../components/seo"

export default function Home({ data }) {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return  (
      <Layout>
        <SEO />
        <div>
          <div className="keni-mv_wrap keni-mv_bg keni-mv_wide">
            <div className="keni-mv_outer">
              <div className="background">
                <Img fluid={sources} alt="" />
              </div>
              <div className="catch-area catch-area_r">
                <div className="catch-area_inner">
                    <p className="catch_main">勉強は，実は楽しい</p>
                    <p className="catch_sub">変化する時代に合わせた学び</p>
                    <div className="keni-mv_btn"><a href="#main"><span className="icon_arrow_s_down" /></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="keni-main_wrap">
          <div className="keni-main_outer">
            <main id="main" className="keni-main">
              <div className="keni-main_inner">
                <div className="keni-section_wrap">
                  <article className="keni-section">
                    <h2>最新情報</h2>
                    <div className="news">
                      <article className="news-item">
                        <div className="news-date"><time dateTime="2024-04-01">2024年4月1日</time></div>
                        <div className="news-cat">
                          <span className="cat cat001">お知らせ</span><span className="cat cat002">新刊</span>
                        </div>
                        <p className="news-cont">弊社で執筆した新規書き下ろし書籍，<a href="https://amzn.to/3TBLZcN">「徹底攻略 基本情報技術者教科書 令和6年度」</a>が発売されました。最新シラバス9.0に完全対応し，内容を刷新しています。その他詳細は<a href="/#fe">こちら</a>です。</p>
                      </article>
                      <article className="news-item">
                        <div className="news-date"><time dateTime="2023-03-22">2024年3月22日</time></div>
                        <div className="news-cat">
                          <span className="cat cat001">お知らせ</span><span className="cat cat002">新刊</span>
                        </div>
                        <p className="news-cont">弊社で執筆しております書籍，<a href="https://amzn.to/3TJICS9">「徹底攻略 データベーススペシャリスト教科書 令和6年度」</a>が発売されました。最新シラバス4.1に対応し，AI・データサイエンス系の内容を中心に充実させています。その他詳細は<a href="/#db">こちら</a>です。</p>
                        </article>
                      <article className="news-item">
                        <div className="news-date"><time dateTime="2023-12-21">2023年12月21日</time></div>
                        <div className="news-cat">
                          <span className="cat cat001">お知らせ</span><span className="cat cat002">新刊</span>
                        </div>
                        <p className="news-cont">弊社で執筆しております書籍，<a href="https://amzn.to/4axx3E0">「徹底攻略 情報セキュリティマネジメント教科書 令和6年度」</a>が発売されました。試験内容の変化に対応しています。その他詳細は<a href="/#sg">こちら</a>です。</p>
                      </article>
                      <p className="link-next"><a href="/history/">以前の情報はこちら</a></p>
                    </div>
                    {/*keni-section*/}
                  </article></div>
                <div className="keni-section_wrap">
                  <section className="keni-section">
                  <h2>お知らせ</h2>
                    <a id="fe" name="fe" />
                    <h4>基本情報技術者教科書 令和6年度が発売されました</h4>
                    <div className="contents">
                      弊社で執筆した新規書き下ろし書籍，<a href="https://amzn.to/3TBLZcN"><img className="fl-r" src="https://img.ips.co.jp/ij/23/1123101063/1123101063-520x.jpg" alt="基本情報技術者　教科書画像" width={150} /></a><br />
                      <a href="https://amzn.to/3TBLZcN">「徹底攻略 基本情報技術者教科書 令和6年度」</a>が発売されました。最新シラバス9.0に対応し，基本情報技術者試験に必要な知識をしっかり学習できる定番書です。電子書籍も各種取りそろえています。<br />
                      特典として，<span className="b red">電子版（PDF）</span>と<span className="b red">単語帳</span>が付属しています。<br />
                      詳細は<a href="https://book.impress.co.jp/books/1123101063">公式ページ</a>をご参照ください。紙の書籍をご購入されても，電子書籍ダウンロードサービスがあります。<br />
                      フォローアップについては，<a href="https://book.impress.co.jp/books/1123101063#box-errata">正誤表</a>，<a href="https://book.impress.co.jp/books_inquiry/form.html?ic=1123101063">お問い合わせ</a>を出版元であるインプレスジャパン様の公式ページで公開，お問い合わせを受けています。<br />
                      <span className="small b">（<a href="https://amzn.to/3TBLZcN">amazon</a>で在庫が無い場合の，その他通販サイトは<a href="https://book.impress.co.jp/books/1123101063">公式ページ</a>をご参照ください。）</span>
                    </div>
                    <p />{/*conts*/}
                    <a id="db" name="db" />
                    <h4>データベーススペシャリスト教科書 令和6年度が発売されました</h4>
                    <div className="contents">
                      弊社で執筆しております書籍，<a href="https://amzn.to/3TJICS9"><img className="fl-r" src="https://img.ips.co.jp/ij/23/1123101134/1123101134-520x.jpg" alt="データベーススペシャリスト　教科書画像" width={150} /></a><br />
                      <a href="https://amzn.to/3TJICS9">「徹底攻略 データベーススペシャリスト教科書 令和6年度」</a>が発売されました。最新シラバス4.1に対応し，データベーススペシャリスト試験に必要な知識をしっかり学習できる定番書です。電子書籍も各種取りそろえています。<br />
                      しっかり演習できるよう，過去問解説は，テキスト掲載の1期分に加えて，10期分のダウンロード特典があり，<span className="b red">合計で<u>11期分</u>を利用できます</span>。その他の特典として，<span className="b red">単語帳も付属</span>しています。<br />
                      詳細は<a href="https://book.impress.co.jp/books/1123101134">公式ページ</a>をご参照ください。紙の書籍をご購入されても，電子書籍ダウンロードサービスがあります。<br />
                      フォローアップについては，<a href="https://book.impress.co.jp/books/1123101134#box-errata">正誤表</a>，<a href="https://book.impress.co.jp/books_inquiry/form.html?ic=1123101134">お問い合わせ</a>を出版元であるインプレスジャパン様の公式ページで公開，お問い合わせを受けています。<br />
                      <span className="small b">（<a href="https://amzn.to/3TJICS9">amazon</a>で在庫が無い場合の，その他通販サイトは<a href="https://book.impress.co.jp/books/1123101134">公式ページ</a>をご参照ください。）</span>
                    </div>
                    <p />{/*conts*/}
                    <a id="sg" name="sg" />
                    <h4>情報セキュリティマネジメント教科書 令和6年度が発売されました</h4>
                    <div className="contents">
                      弊社で執筆しております書籍，<a href="https://amzn.to/4axx3E0"><img className="fl-r" src="https://img.ips.co.jp/ij/23/1123101094/1123101094-520x.jpg" alt="情報セキュリティマネジメント　教科書画像" width={150} /></a><br />
                      <a href="https://amzn.to/4axx3E0">「徹底攻略 情報セキュリティマネジメント教科書 令和6年度」</a>が発売されました。情報セキュリティマネジメント試験対策に必要な知識をしっかり学習できる定番書です。電子書籍も各種取りそろえています。<br />
                      今回は，実際の業務に役立つ例として，科目B問題を元にした事例紹介を最初に追加しました。<span className="b red">特典として単語帳も付属</span>しています。<br />
                      詳細は<a href="https://book.impress.co.jp/books/1123101094">公式ページ</a>をご参照ください。動画解説サイト（<a href="https://www.wakuwakuacademy.net/">わくわくアカデミー</a>）との連携や，書籍ご購入者限定，電子書籍ダウンロードサービスなど，特典いっぱいの書籍です。過去問解説は<u>全8期分</u>，さらに試験センターから公開された<u>サンプル問題</u>の全問題解説を付録として掲載しています。<br />
                      フォローアップについては，<a href="https://book.impress.co.jp/books/1123101094#box-errata">正誤表</a>，<a href="https://book.impress.co.jp/books_inquiry/form.html?ic=1123101094">お問い合わせ</a>を出版元であるインプレスジャパン様のページで公開，お問い合わせを受けています。<br />
                      <span className="small b">（<a href="https://amzn.to/4axx3E0">amazon</a>で在庫が無い場合，その他通販サイトは<a href="https://book.impress.co.jp/books/1123101094">公式ページ</a>をご参照ください。）</span>
                    </div>
                    <p />{/*conts*/}
                    <a id="ap" name="ap" />
                    <h4>応用情報技術者教科書 令和6年度が発売されました</h4>
                    <div className="contents">
                      弊社で執筆しております書籍，<a href="https://amzn.to/3s6eh5l"><img className="fl-r" src="https://img.ips.co.jp/ij/23/1123101067/1123101067-520x.jpg" alt="応用情報技術者　教科書画像" width={150} /></a><br />
                      <a href="https://amzn.to/3s6eh5l">「徹底攻略 応用情報技術者教科書 令和6年度」</a>が発売されました。応用情報技術者試験対策に必要な知識をしっかり学習できる定番書です。電子書籍も各種取りそろえています。<br />
                      弊社書籍でおなじみの，<span className="box-lime">「わく☆すたAI」が出題傾向分析や問題の要約，解説の一部執筆</span>を行い，より効果的な学習を実現できるようにしました。<span className="b red">特典として過去問題の解説や単語帳も付属</span>しています。<br />
                      詳細は<a href="https://book.impress.co.jp/books/1123101067">公式ページ</a>をご参照ください。書籍ご購入者限定，電子書籍ダウンロードサービスなど，特典いっぱいの書籍です。<span className="b red">過去問解説は<u>20回分</u>をダウンロードと合わせて利用できます！</span><br />
                      フォローアップについては，<a href="https://book.impress.co.jp/books/1123101067#box-errata">正誤表</a>，<a href="https://book.impress.co.jp/books_inquiry/form.html?ic=1123101067">お問い合わせ</a>を出版元であるインプレスジャパン様のページで公開，お問い合わせを受けています。
                    </div>
                    <p />{/*conts*/}
                    <a id="sc" name="sc" />
                    <h4>情報処理安全確保支援士教科書 令和6年度が発売されました</h4>
                    <div className="contents">
                      弊社で執筆しております書籍，<a href="https://amzn.to/46hFqRF"><img className="fl-r" src="https://img.ips.co.jp/ij/23/1123101058/1123101058-520x.jpg" alt="情報処理安全確保支援士教科書画像" width={150} /></a><br />
                      <a href="https://amzn.to/46hFqRF">「徹底攻略 情報処理安全確保支援士教科書 令和6年度」</a>が発売されました。情報処理安全確保支援士試験対策に必要な知識をしっかり学習できる定番書です。「解説→例題」の積み重ねを行うアジャイル学習法で，確実に知識を定着させることができます。電子書籍も各種取りそろえています。<br />
                      しっかり演習できるよう，過去問解説は，テキスト掲載の1期分に加えて，12期分のダウンロード特典があり，<span className="b red">合計で<u>13期分</u>を利用できます</span>（印刷可。令和5年秋期試験の解説は，試験後にダウンロード提供予定）。その他の特典として，<span className="b red">単語帳も付属</span>しています。<br />
                      詳細は<a href="https://book.impress.co.jp/books/1123101058">公式ページ</a>をご参照ください。<br />
                      フォローアップについては，<a href="https://book.impress.co.jp/books/1123101058#box-errata">正誤表</a>，<a href="https://book.impress.co.jp/books_inquiry/form.html?ic=1123101058">お問い合わせ</a>を出版元であるインプレスジャパン様のページで公開，お問い合わせを受けています。<br />
                      <span className="small b">（<a href="https://amzn.to/46hFqRF">amazon</a>で在庫が無い場合の，その他通販サイトは<a href="https://book.impress.co.jp/books/1123101058">公式ページ</a>をご参照ください。）</span>
                    </div>
                    <p />{/*conts*/}
                    <a id="nw" name="nw" />
                    <h4>ネットワークスペシャリスト教科書 令和6年度が発売されました</h4>
                    <div className="contents">
                      弊社で執筆しております書籍，<a href="https://amzn.to/3OWSPY2"><img className="fl-r" src="https://img.ips.co.jp/ij/23/1123101042/1123101042-520x.jpg" alt="ネットワークスペシャリスト　教科書画像" width={150} /></a><br />
                      <a href="https://amzn.to/3OWSPY2">「徹底攻略 ネットワークスペシャリスト教科書 令和6年度」</a>が発売されました。ネットワークスペシャリスト試験対策に必要な知識をしっかり学習できる定番書で，試験傾向の変化に合わせて海底を毎年行っています。電子書籍も各種取りそろえています。<br />
                      しっかり演習できるよう，過去問解説は，テキスト掲載の1期分に加えて，11期分のダウンロード特典があり，<span className="b red">合計で<u>12期分</u>を利用できます</span>。その他の特典として，<span className="b red">単語帳も付属</span>しています。<br />
                      フォローアップについては，<a href="https://book.impress.co.jp/books/1123101042#box-errata">正誤表</a>，<a href="https://book.impress.co.jp/books_inquiry/form.html?ic=1123101042">お問い合わせ</a>を出版元であるインプレスジャパン様のページで公開，お問い合わせを受けています。<br />
                      <span className="small b">（<a href="https://amzn.to/3OWSPY2">amazon</a>で在庫が無い場合の，その他通販サイトは<a href="https://book.impress.co.jp/books/1123101042">公式ページ</a>をご参照ください。）</span>
                    </div>
                    <p />{/*conts*/}
                    <a id="kamokuB" name="kamokuB" />
                    <h4>徹底攻略 基本情報技術者の科目B実践対策 が発売されました</h4>
                    <div className="contents">
                      弊社で執筆しております書籍，<a href="https://amzn.to/3PoADbq"><img className="fl-r" src="https://img.ips.co.jp/ij/22/1122101039/1122101039-520x.jpg" alt="徹底攻略 基本情報技術者の科目B実践対策 画像" width={150} /></a><br />
                      <a href="https://amzn.to/3PoADbq">「徹底攻略 基本情報技術者の科目B実践対策」</a>が発売されました。演習問題を数多く掲載し，基本情報技術者の科目B試験に必要な知識をしっかり学習できる定番書です。プログラミング・アルゴリズム・情報セキュリティなど，科目B試験で出題される内容すべてに対応しています。電子書籍も各種取りそろえています。<br />
                      書籍中で，<a href="https://www.ipa.go.jp/shiken/syllabus/henkou/2022/henkou20220425.html">公開されている基本情報技術者試験の科目Bサンプル問題</a>すべての解説の他，予想問題を各種掲載しています。<b>本書に登場する擬似言語のプログラムをPythonに変換したものを，<a href="https://github.com/wakuwakustudyworld/fe-kamokuB">GitHubに公開</a>しています。</b><br />
                      詳細は<a href="https://book.impress.co.jp/books/1122101039">公式ページ</a>をご参照ください。<br />
                      フォローアップについては，<a href="https://book.impress.co.jp/books/1122101039#box-errata">正誤表</a>，<a href="https://book.impress.co.jp/books_inquiry/form.html?ic=1122101039">お問い合わせ</a>を出版元であるインプレスジャパン様のページで公開，お問い合わせを受けています。
                    </div>
                  </section></div>{/*article-body*/}
                {/*keni-section*/}
              </div>
            </main>
          </div>
        </div>
      </Layout>
  )
}

export const query = graphql`
  query {
    mobileImage: file(relativePath: {eq: "wakusuta_books_sp.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(relativePath: {eq: "wakusuta_books.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`